import React, { useContext, useEffect, useRef, useState } from "react";
import { LayoutContext } from "../../context/layout.context";

import { PngIcons, SvgIcons } from "../../icons";
import AuthService from "../../services/Auth";
import CircularProgress from "@mui/material/CircularProgress";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Slider from "@mui/material/Slider";
import { ColorScheme } from "../../enums";
import { Link } from "react-router-dom";
import { DeleteOutline, Visibility } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import { useAuth } from "../../outseta/AuthProvider";

export default function DiscountWainoExplorer() {
  const layout = useContext(LayoutContext);
  
  const [state, setState] = useState({
    loader           : false,
    mainLoader       : true,
    fetchedData      : [],
    smallScreenFilter: false,
    filteredWines    : [],
    currentPage      : 1,
    winesLoader      : false,
    totalCount       : '',
    filterApplied    : false,
  });

  const [filters, setFilters] = useState({
    sellerName     : [],
    wineName       : "",
    priceRange     : [],
    ratingRange    : [0, 5],
    countries      : [],
    grape          : [],
    discountCheck  : false,
    newlyAddedCheck: false,
  });

  const containerRef = useRef(null);

  useEffect(() => {
    layout.setLayout({
      showNav: true,
      isHome: false,
    });
  }, []);

  const onLoadFunc = async () => {
    await loadData({page: state.currentPage});
  }

  const loadData = async ({page, reset}) => {
    let query = {page: page || 1 , limit : 100};
    const { response, error } = await AuthService.FetchData({query});
    let totalCount = 0;
    let filteredData = [];
    if (response.data.wines) {
      filteredData = response.data.wines;
      totalCount=response.data.totalCount;
    }
    console.log('response  ', response.data);
    if(reset){
      let data = [];
      data = [...response.data.wines];
      setState({
        ...state,
        loader: false,
        mainLoader: false,
        fetchedData: data,
        filteredWines: [],
        filteredWines : data,
        winesLoader : false,
        smallScreenFilter: false,
        filterApplied : false,
      });
    }
    else{ 
      setState({
        ...state,
        loader: false,
        mainLoader: false,
        fetchedData: [...state.fetchedData, ...response.data.wines],
        currentPage: page,
        filteredWines : [...state.filteredWines, ...response.data.wines],
        winesLoader : false,
        totalCount: totalCount,
      });
    }
  };

  console.log('state ', state);

  useEffect(() => {
    onLoadFunc();
  }, []);


  useEffect(() => {
    onLoadFunc();
  }, []);

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      !state.filterApplied &&
      container &&
      container.scrollTop + container.clientHeight >= container.scrollHeight - 20
    ) {
      // Load more data when user is near the bottom
      loadData({page : state.currentPage + 1});
    }
  };


  return (
    <div id="WainoExplorer" onScroll={handleScroll} ref={containerRef}>
      {state.mainLoader ? (
        <div className="absoluteMiddle">
          <CircularProgress />
        </div>
      ) : (
        <ListingComponent loadData={loadData} filters={filters} setFilters={setFilters} containerRef={containerRef}  state={state} setState={setState} />
      )}
    </div>
  );
}

const PLANS = {
  FREE: { label: "Free Plan", uid: "y9qzZ0WA" },
  BASIC: { label: "Unlock discounts & new wines plan", uid: "wQXw5ZmK" }
};

function hasCorrectPlan(user) {
  if (user && user?.Account?.CurrentSubscription?.Plan?.Uid) {
    const userPlanUid = user.Account.CurrentSubscription.Plan.Uid;
    console.log('userPlanUid ', userPlanUid);
    return userPlanUid === PLANS.BASIC.uid;
  } else {
    return false;
  }
}

const ListingComponent = ({ state, filters, setFilters, setState, containerRef, loadData }) => {
  const [apiLoader, setApiLoader] = useState(false);
  const { user, openLogin, openSignup, openProfile, isLoading } = useAuth();
  const proPlan = hasCorrectPlan(user);
  
  const getUniqueWineryNames = (data) => {
    
    const uniqueNames = [
      // "Ockhuysen",
      'Gall & Gall',
      'Vinites',
      // 'Dirk van der Broek',
      'Albert Heijn',
      // 'Plus Supermarkten',
      // 'Dekamarkt',
      'Siersma',
      // 'Melgers',
      // 'Cordier Wines',
      // 'Tesco SK'
    ];

    return uniqueNames;
  };

  const uniqueWineryNames = getUniqueWineryNames();

  const handleChangePrice = (priceRange) => {
    const index = filters.priceRange.findIndex(
      ([minPrice, maxPrice]) =>
        minPrice === priceRange[0] && maxPrice === priceRange[1],
    );

    const updatedPriceRange = [...filters.priceRange];
    if (index !== -1) {
      updatedPriceRange.splice(index, 1);
    } else {
      updatedPriceRange.push(priceRange);
    }

    updatedPriceRange.sort((a, b) => a[0] - b[0]);
    setFilters({ ...filters, priceRange: updatedPriceRange });
  };

  const handleNameChange = (e) => {
    e.preventDefault();
    setFilters({ ...filters, wineName: e.target.value });
    applyFiltersFunction(e.target.value);
    if(e.target.value == ""){
      applyFiltersFunction("");
    }
  };

  const handleChangeRatings = (ratings, value) => {
    setFilters({ ...filters, ratingRange: value });
  };

  const priceRangeOptions = [
    { label: "1-5", value: [1, 5] },
    { label: "5-10", value: [5, 10] },
    { label: "10-15", value: [10, 15] },
    { label: "15-25", value: [15, 25] },
    { label: "25-50", value: [25, 50] },
    { label: "50-100", value: [59, 100] },
    { label: "100-500", value: [100, 500] },
  ];

  
  const applyFiltersFunction = async(wineName) => {
    setApiLoader(true);
    let payload = {
      sellerName     : filters.sellerName,
      ratingRange    : filters.ratingRange,
      priceRange     : filters.priceRange,
      wineName       : (typeof wineName == "string" && wineName) || filters.wineName,
      newlyAddedCheck: filters.newlyAddedCheck,
      discountCheck  : filters.discountCheck,
    };

    try {
      const { response, error } = await AuthService.FilterData({payload});
      setState({...state, filteredWines : response.data.wines, filterApplied: true, smallScreenFilter: false})
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Handle error...
    }
    setApiLoader(false);


    containerRef.current.scrollTop = 0;
    // setFilteredWines(filteredData);
  };

  console.log('filtered ', state.filteredWines)


  const resetFiltersFunc = () => {
    setFilters({
      sellerName: [],
      wineName: "",
      priceRange: [],
      ratingRange: [0, 5],
      countries: [],
      grape: [],
      newlyAddedCheck: false,
      discountCheck: false,
    });
    loadData({page : 1, reset: true});
  };

  const countWinesInPriceRanges = (wines, priceRangeOptions) => {
    const countMap = {};

    // Initialize the countMap with 0 for each price range
    priceRangeOptions.forEach((option) => {
      countMap[option.label] = 0;
    });

    // Count the wines in each price range
    wines.forEach((wine) => {
      const winePrice = parseFloat(wine.current_price);
      priceRangeOptions.forEach((option) => {
        if (winePrice >= option.value[0] && winePrice <= option.value[1]) {
          countMap[option.label]++;
        }
      });
    });

    return countMap;
  };

  const handleChangeGrape = (grape) => {
    const updatedGrape = [...filters.grape];
    const index = updatedGrape.indexOf(grape);
    if (index !== -1) {
      updatedGrape.splice(index, 1);
    } else {
      updatedGrape.push(grape);
    }

    setFilters({ ...filters, grape: updatedGrape });
  };

  const handleChangeSeller = (event, values) => {
    setFilters({ ...filters, sellerName: values });
  };

  return (
    <div id="ListingComponent">
      <div className="topSectionSticky">
        <h2 className="Heading22B pt_40">
          Showing Results for {state.filteredWines.length} wines out of {state.totalCount} wines
        </h2>
        <div className="mt_24 singleLineFilter">
          <div
            className="filterTitle Heading18M"
            onClick={() =>
              setState({
                ...state,
                smallScreenFilter: !state.smallScreenFilter,
              })
            }
          >
            Filters
          </div>
          <div className="searchBox">
            <h2 className="Heading22B">Search Wine, Winery or Grape</h2>
            <div className="position-relative">
              <TextField
                variant   = "standard"
                className = "w-100 pr_32 searchField"
                onChange  = {(e) => handleNameChange(e)}
                value     = {filters.wineName}
              />
              <span
                className="deleteIcon cp"
                onClick={() => {
                  setFilters({ ...filters, wineName: "" });
                  applyFiltersFunction();
                }}
              >
                <DeleteOutline />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex space-between">
        <div
          className={`leftSection ${
            state.smallScreenFilter && "filterAppliedBox"
          }`}
        >
          <div className="d-flex space-between">
            <h2 className="Heading22B mt_32">Seller Name</h2>
            {state.smallScreenFilter && (
              <div
                className="closeIconFilter"
                onClick={() => setState({ ...state, smallScreenFilter: false })}
              >
                <SvgIcons.CloseIcon color={ColorScheme.ColorSchemeCode.black} />
              </div>
            )}
          </div>
          <Autocomplete
            multiple // Allow multiple selections
            options        = {uniqueWineryNames}
            getOptionLabel = {(option) => option}
            id             = "auto-complete"
            autoComplete
            onChange = {handleChangeSeller}  // Update the event handler
            value    = {filters.sellerName}  // Pass the selected values
            includeInputInList
            renderInput={(params) => (
              <TextField {...params} label="Select" variant="standard" />
            )}
          />
          <h2 className="Heading22B mt_24 d-flex space-between align-items-center">
            Price Range
            <div className="Heading16M">EUR</div>
          </h2>
          <div className="price-buttons">
            {priceRangeOptions.map((option) => (
              <button
                key={option.label}
                className={
                  filters.priceRange.some(
                    ([minPrice, maxPrice]) =>
                      minPrice === option.value[0] &&
                      maxPrice === option.value[1],
                  )
                    ? "price selected"
                    : "price"
                }
                onClick={() => handleChangePrice(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
          <h2 className="Heading22B mt_24 d-flex space-between align-items-center">
            Ratings
            <div className="Heading16M">Out of 5</div>
          </h2>
          <div className="mt_16">
            <div className="d-flex space-between mb_8">
              <p className="Caption12M">{filters.ratingRange[0]}</p>
              <p className="Caption12M">{filters.ratingRange[1]}</p>
            </div>
            <Slider
              value             = {filters.ratingRange}
              onChange          = {handleChangeRatings}
              valueLabelDisplay = "auto"
              aria-labelledby   = "range-slider"
              max               = {5}
              step              = {0.1}
            />
          </div>
       
          <>
            <h2 className={`${!proPlan && 'disabled'} Heading22B mt_24 d-flex space-between align-items-center`}>
              Discount
            </h2>
            <div className={`${!proPlan && 'disabled'} d-flex mb_8`}>
              <input
                type     = "checkbox"
                label    = {"Show Discounted Wines"}
                value    = {filters.discountCheck}
                onChange = {(e) =>
                  proPlan &&
                  setFilters({
                    ...filters,
                    discountCheck: !filters.discountCheck,
                  })
                }
              />
              <span className="ml_10">Show Discounted Only</span>
            </div>
          </>
            <>
              <h2 className={`${!proPlan && 'disabled'} Heading22B mt_24 d-flex space-between align-items-center`}>
                Newly Added
              </h2>
              <div className={`${!proPlan && 'disabled'} d-flex mb_8`}>
                <input
                  type     = "checkbox"
                  label    = {"Show newly added wines"}
                  value    = {filters.newlyAddedCheck}
                  onChange = {(e) =>
                    setFilters({
                      ...filters,
                      newlyAddedCheck: !filters.newlyAddedCheck,
                    })
                  }
                />
                <span className="ml_10">Show newly added wines only</span>
              </div>
            </>
            {user?.Account?.CurrentSubscription?.Plan?.Uid != "wQXw5ZmK" && <div className="text-left mt_24">
            <div className="Heading16M text-center">Please <Link><span onClick   = {() => openProfile({ tab: "planChange" })}>Upgrade</span></Link> your plan to continue exploring Discounted wines.</div>
        </div>}
          <div className="mt_24 d-flex space-between mb_100" >
            <CustomButton
              className = "w-48"
              btntext   = {"Apply Filters"}
              padding   = "xl"
              onClick   = {applyFiltersFunction}
              icon      = {apiLoader && <CircularProgress size={16} color="inherit" />}
            />
            <CustomButton
              className = "w-48 resetBtn"
              btntext   = {"Reset Filters"}
              variant   = "dangerTertiary"
              onClick   = {resetFiltersFunc}
            />
          </div>
        </div>
        <div className="rightSection">
        {
          apiLoader ? <div className="middle"><CircularProgress /></div> :
          state.filteredWines.map((wine) => (
            <div className="card mb-3 position-relative">
              <div className="row no-gutters">
                <div className="col-md-3 pr_16 d-flex space-between">
                  <img
                    src       = {wine.image_url}
                    style     = {{ objectFit: "contain" }}
                    width     = {"100%"}
                    height    = {210}
                    className = "card-img"
                    alt       = "..."
                  />
                  <div className="middle ratingFix">
                    <div className="d-flex align-items-center">
                      <div className="Heading14B pr_8">
                        Vivino <br /> Rating:
                      </div>
                      <div className="middle">
                        <p className="Heading24M">
                          {!Number.isNaN(Number(wine.ratings))
                            ? wine.ratings
                            : ""}
                        </p>
                        <div style={{ marginTop: "-10px" }}>
                          {!Number.isNaN(Number(wine.ratings)) && (
                            <StarRating rating={parseFloat(wine.ratings)} />
                          )}
                        </div>
                        <p className="Caption12M">
                          {wine.Number_of_ratings_Vivino}
                        </p>
                      </div>
                    </div>
                    {wine?.discounted_price && wine?.discount_description && (
                      <div className="discountBox mt_16">
                        <span className="Heading14B ">Discount:</span>{" "}
                        <span className="Body14R">
                          {/* {wine?.discounted_price} */}
                          <span className="ml_8">
                            {wine?.discount_description}
                          </span>
                        </span>
                      </div>
                    )}
                  </div>
                  {wine?.newly_added_wine && <div class="ribbon down">
                    <div class="content">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/891/891448.png"
                        height="30px"
                        width="30px"
                        className="newWineBanner"
                        alt=""
                      />
                    </div>
                  </div>}
                </div>
                <div className="col-md-9">
                  <div className="card-body">
                    <h5 className="Heading16M mb_0">
                      {wine?.winery_name || "N/A"}
                    </h5>
                    <p className="Heading18B ellipses mb_8">{wine.wine_name}</p>
                    <div className="d-flex space-between">
                      <div>
                        {wine.country && (
                          <p className="card-text">
                            <small className="text-muted d-flex align-items-center">
                              <span className="mr_4">
                                <SvgIcons.LocationIcon />
                              </span>{" "}
                              {wine.country}
                            </small>
                          </p>
                        )}
                        <div className="Heading16M ml_4">
                          {wine?.logo && (
                            <img
                              src={wine?.logo}
                              width="auto"
                              height={"80px"}
                            />
                          )}
                        </div>
                        <div className="d-flex">
                          {wine?.Vivino_url && (
                            <Link
                              target="_blank"
                              to={wine?.Vivino_url}
                              className="Heading14M  ml_4"
                            >
                              Browse Vivino
                            </Link>
                          )}
                          {wine?.product_url && (
                            <Link
                              target="_blank"
                              to={wine?.product_url}
                              className="Heading14M ml_16"
                            >
                              Buy this wine
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* proplan exist only show discounted */}
              {wine.current_price && proPlan &&
                !Number.isNaN(Number(wine.current_price)) && (
                  <div class="priceSection Heading16B">
                    {wine?.discounted_price && (
                      <span>€ {wine?.discounted_price}</span>
                    )}
                    <span
                      className={`${
                        wine?.discounted_price &&
                        "fs-12 line-through ml_8 color-Black"
                      }`}
                    >
                      € {wine.current_price}
                    </span>
                  </div>
                )}

                {/* else dont show discount */}
                {wine.current_price && !proPlan &&
                !Number.isNaN(Number(wine.current_price)) && (
                  <div class="priceSection Heading16B">
                    {wine?.current_price && (
                      <span>€ {wine?.current_price}</span>
                    )}
                  </div>
                )}

            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const StarRating = ({ rating }) => {
  const MAX_STARS = 5;
  const filledStars = Math.floor(rating);
  const hasHalfStar = rating - filledStars >= 0.5;

  const renderStar = (index) => {
    if (index < filledStars) {
      return <span class="fa fa-star checked fs-14"></span>; // Full star: ★
    } else if (hasHalfStar && index === filledStars) {
      return <span class="fa checked fa fa-star-half-o fs-14"></span>; // Half star: ★½
    } else {
      return <span class="fa fa-star fs-14"></span>; // Empty star: ☆
    }
  };

  return (
    <div>
      {Array.from({ length: MAX_STARS }, (_, index) => renderStar(index))}
    </div>
  );
};